import type { Reducer, ReactNode } from 'react'
import {
  useReducer,
  useEffect,
  useContext,
  useMemo,
  createContext,
} from 'react'

import AppRouter from 'next/router'

import { type AuctionSearchSortOrder } from '@b-stock/bstock-next'
import { ddLogger } from '@b-stock/bstock-react'

import type { Range } from '@commonTypes'
import listingSearchQuery from '@queries/listingSearchQuery/listingSearchQuery'

import defaultState from './defaultState'
import type { AuctionFiltersState, AuctionSearchContextValue } from './types'
import { useAuctionStateURLRectification } from './useStateURLRectification'
import { composeQueryFromAuctionState } from './utils'
import type {
  SearchState,
  SearchAction,
  AuctionSearchSortBy,
} from '../BaseSearchProvider/types'

const defaultContextValue = {
  state: defaultState,
  data: null,
  isLoading: true,
  setQuery: (query: string) => {
    void AppRouter.push(`/?q=${query}`)
  },
  setPage: () => null,
  setSort: () => null,
  setFilter: () => null,
  getActiveFilterCount: () => 0,
  updateFilters: () => null,
  resetFilters: () => null,
  updateAuctionData: () => undefined,
}

const handleUndefinedFilter = (filter: never) => {
  // This code should never be executed, but serves as a useful debugging log message
  // if there ever is a bug that causes it to be hit (e.g. new filter added but not
  // yet implemented everywhere)
  // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
  ddLogger.error(`Unexpected filter value ${filter}`, {}, new Error())
}
const filterContribution = (value: string[]) => value.length
const rangeContribution = ([min, max]: Range) =>
  min === null && max === null ? 0 : 1

const getActiveFilterCount = (filters: AuctionFiltersState): number => {
  return (Object.keys(filters) as (keyof AuctionFiltersState)[]).reduce(
    (acc: number, key): number => {
      switch (key) {
        case 'sellerId':
          // `sellerId` filter deliberately omitted as it's not used on the all listings
          // page, and is a forced filter on the storefront page.
          return acc
        case 'category':
        case 'manufacturer':
        case 'condition':
        case 'packagingCondition':
        case 'cosmeticCondition':
        case 'inventoryType':
        case 'shipmentType':
        case 'functionality':
        case 'accessories':
        case 'productType':
        case 'businessUnit':
        case 'regions':
        case 'warehouseLocation':
          return acc + filterContribution(filters[key])
        case 'retailValueRanges':
        case 'currentBidRanges':
        case 'numberOfUnitsRanges':
          return acc + rangeContribution(filters[key])
      }
      // This code should never be executed
      handleUndefinedFilter(key)
      return acc
    },
    0
  )
}

const SearchContext =
  createContext<AuctionSearchContextValue>(defaultContextValue)

const reducer: Reducer<
  SearchState<AuctionFiltersState>,
  SearchAction<AuctionFiltersState>
> = (state, action) => {
  switch (action.type) {
    case 'SET_STATE':
      return action.state
    case 'SET_QUERY':
      return {
        ...state,
        query: action.query,
        page: 1,
      }
    case 'SET_SORT':
      return {
        ...state,
        page: 1,
        sortBy: action.sortBy,
        sortOrder: action.sortOrder,
      }
    case 'SET_PAGE':
      return {
        ...state,
        page: action.page,
      }
    case 'UPDATE_FILTERS':
      return {
        ...state,
        filters: {
          ...state.filters,
          ...action.filters,
        },
      }
    case 'RESET_FILTERS':
      return {
        ...state,
        filters: defaultState.filters,
      }
  }
}

type SearchProviderProps = {
  initialState?: SearchState<AuctionFiltersState>
  /**
   * Filters that will always be applied and can't be changed. Won't be counted
   * as applied for presentation of active filters.
   */
  forceFilters?: Partial<AuctionFiltersState>
  children: ReactNode
}

const AuctionSearchProvider = ({
  initialState = defaultState,
  forceFilters,
  children,
}: SearchProviderProps) => {
  const [state, dispatch] = useReducer(reducer, initialState)
  const params = composeQueryFromAuctionState({
    ...state,
    filters: { ...state.filters, ...forceFilters },
  })
  const {
    results: { data, isPlaceholderData },
    updateAuctionData,
  } = listingSearchQuery.useWithUpdater({
    params,
  })
  useAuctionStateURLRectification(state, dispatch, forceFilters)

  // Update the reducer state wholesale when `initialState` changes
  useEffect(() => {
    dispatch({
      type: 'SET_STATE',
      state: { ...initialState, ...(forceFilters ?? {}) },
    })
  }, [initialState, forceFilters])

  const contextValue = useMemo(
    () => ({
      state,
      data: data || null,
      isLoading: isPlaceholderData,
      getActiveFilterCount: () => {
        return getActiveFilterCount(state.filters)
      },
      setQuery: (query: string) =>
        dispatch({
          type: 'SET_QUERY',
          query,
        }),
      setPage: (page: number) =>
        dispatch({
          type: 'SET_PAGE',
          page,
        }),
      setSort: (
        sortBy: AuctionSearchSortBy,
        sortOrder: AuctionSearchSortOrder
      ) =>
        dispatch({
          type: 'SET_SORT',
          sortBy,
          sortOrder,
        }),
      updateFilters: (filters: Partial<AuctionFiltersState>) =>
        dispatch({
          type: 'UPDATE_FILTERS',
          filters,
        }),
      resetFilters: () => dispatch({ type: 'RESET_FILTERS' }),
      updateAuctionData,
    }),
    [state, data, isPlaceholderData]
  )

  return (
    <SearchContext.Provider value={contextValue}>
      {children}
    </SearchContext.Provider>
  )
}

const useAuctionSearch = () => useContext(SearchContext)

export {
  useAuctionSearch,
  SearchContext,
  defaultContextValue,
  getActiveFilterCount,
}

export default AuctionSearchProvider
