import { AuctionSearchSortOrder } from '@b-stock/bstock-next'

import type { AuctionFiltersState } from './types'
import type { SearchState } from '../BaseSearchProvider/types'

const defaultState: SearchState<AuctionFiltersState> = {
  query: null,
  page: 1,
  sortBy: 'endTime',
  sortOrder: AuctionSearchSortOrder.Ascending,
  filters: {
    category: [],
    // sellers: [], // 7-7-:23: found not working, use sellerId
    sellerId: [], // string[] - seller ids
    retailValueRanges: [null, null],
    manufacturer: [],
    condition: [],
    packagingCondition: [], // Corrected property name
    cosmeticCondition: [],
    inventoryType: [], // New property
    currentBidRanges: [null, null],
    shipmentType: [],
    functionality: [],
    accessories: [],
    productType: [],
    businessUnit: [],
    numberOfUnitsRanges: [null, null],
    regions: [],
    warehouseLocation: [],
  },
}

export default defaultState
