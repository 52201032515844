import type * as React from 'react'

import { styled } from 'styled-components'

import { Radio } from '@b-stock/bstock-react'

const StyledRadioButton = styled(Radio)`
  & span:first-child {
    margin-right: 5px;
  }
  & span:nth-child(2) {
    text-transform: capitalize;
  }
`

type FilterRadioButtonProps = Omit<React.ComponentProps<typeof Radio>, 'size'>

const FilterRadioButton: React.FC<FilterRadioButtonProps> = (props) => {
  return <StyledRadioButton size={14} {...props} />
}

export default FilterRadioButton
