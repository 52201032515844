import type * as React from 'react'

import { styled } from 'styled-components'

import { Button, Spinner, FormattedMessage } from '@b-stock/bstock-react'
import { designColors } from '@b-stock/bstock-react/theme'

type ShowResultsProps = {
  loading: boolean
  onSubmit: () => void
  results: string
}

const SButton = styled(Button)`
  &&& {
    background: ${designColors.primary.brightBlue};
  }
`

const StSpinner = styled(Spinner)`
  &&& {
    margin: 0 0.4rem;
  }
`

const TextContainer = styled.div`
  margin-right: 0.4rem;
`

const ShowResultsButton: React.FC<ShowResultsProps> = ({
  loading,
  onSubmit,
  results,
}) => {
  const s = <StSpinner size="12px" padding="0" text="" />

  let showResultsText

  if (!loading && !results) {
    showResultsText = (
      <FormattedMessage
        id="MobileSearchFilters.actions.submit.simple"
        defaultMessage="MobileSearchFilters.actions.submit.simple"
        values={{
          results,
        }}
      />
    )
  } else if (loading) {
    showResultsText = (
      <FormattedMessage
        id="MobileSearchFilters.actions.submit.loading"
        defaultMessage="MobileSearchFilters.actions.submit.loading"
      />
    )
  } else {
    showResultsText = (
      <FormattedMessage
        id="MobileSearchFilters.actions.submit"
        defaultMessage="MobileSearchFilters.actions.submit"
        values={{
          results,
        }}
      />
    )
  }

  return (
    <SButton onClick={onSubmit}>
      <TextContainer>{showResultsText}</TextContainer>
      {loading && s}
    </SButton>
  )
}

export default ShowResultsButton
