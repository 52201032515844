import { useState, useCallback, useRef } from 'react'
import type * as React from 'react'

import { faTimes } from '@fortawesome/pro-light-svg-icons'
import { faSearch } from '@fortawesome/pro-regular-svg-icons'

import {
  SearchWrapper,
  InputSearch,
  SearchIcon,
  ClearIcon,
  SubmitButton,
} from './ui'

type SearchBarProps = {
  onSubmit: (value: string, e: React.FormEvent) => void
  placeholder?: string
  initialValue?: string | null
  className?: string
  inUse?: boolean
}

export const SearchBar = ({
  className,
  onSubmit,
  placeholder,
  initialValue = '', // this allows value to be saved in input when searching from other pages
}: SearchBarProps) => {
  const inputRef = useRef<HTMLInputElement | null>(null)
  const [inputValue, setInputValue] = useState<string>(initialValue || '')
  const [formIsActive, setFormActive] = useState(false)

  const handleFocus = useCallback(() => setFormActive(true), [])
  const handleBlur = useCallback(() => setFormActive(false), [])

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault()
    setFormActive(false)
    onSubmit(inputValue, e)
    inputRef.current?.blur()
  }

  const clearSearch = (e: React.FormEvent) => {
    e.preventDefault()
    inputRef.current?.focus()
    setInputValue('')
    onSubmit('', e)
  }

  return (
    <SearchWrapper
      className={className}
      onSubmit={handleSubmit}
      id="search-bar-wrapper"
      data-testid="search-bar-wrapper"
    >
      <InputSearch
        ref={inputRef}
        id="auction-search-bar"
        autoComplete="off"
        name="search"
        placeholder={formIsActive ? '' : placeholder}
        onFocus={handleFocus}
        onBlur={handleBlur}
        value={inputValue}
        onChange={(e) => setInputValue(e.target.value)}
      />

      {inputValue.length > 0 && (
        <ClearIcon icon={faTimes} onClick={clearSearch} />
      )}

      <SubmitButton>
        <SearchIcon icon={faSearch} />
      </SubmitButton>
    </SearchWrapper>
  )
}
