import type { AuctionUpdates } from '@components/features/auction/shared/useAuctionUpdates'
import type { SearchResultWithAuction } from '@queries/listingSearchQuery/types'

const applyAuctionUpdates = (
  updates: AuctionUpdates,
  previous: SearchResultWithAuction
): SearchResultWithAuction => {
  let mergedResult = {
    ...previous,
  }
  if (updates.type === 'public' || updates.type === 'poll') {
    mergedResult = {
      ...mergedResult,
      winningBidAmount: updates.data.winningBidAmount,
      numberOfBids: updates.data.numberOfBids,
      // actualEndTime and initialEndTime *shouldn't* ever be undefined if it gets
      // to this point, but the type doesn't strictly guarantee this, so we need
      // a fallback to satisfy typescript (even though that fallback shouldn't
      // ever be needed in practice)
      endTime: updates.data.actualEndTime ?? previous.endTime,
      initialEndTime: updates.data.initialEndTime ?? previous.initialEndTime,
      ...(updates.type === 'poll'
        ? {
            bid: updates.data.bid,
            bidStatus: updates.data.bidStatus,
          }
        : {}),

      auction: {
        ...mergedResult.auction,
        // Both public and poll updates will include a subset of Auction or LimitedAuction fields,
        // plus potentially a few extra field that definitely won't exist on Auction nor LimitedAuction,
        // so just applying them directly is safe
        ...updates.data,
      },
    }
  } else {
    // It's one of the other updates, so just pull out the auction-specific fields
    mergedResult = {
      ...mergedResult,
      bidStatus: updates.data.bidStatus,
      ...('bid' in updates.data ? { bid: updates.data.bid } : {}),
      auction: {
        ...mergedResult.auction,
        winning: updates.data.winning,
        ...('closed' in updates.data ? { closed: updates.data.closed } : {}),
        ...('userBidAmount' in updates.data
          ? { userBidAmount: updates.data.userBidAmount }
          : {}),
      },
    }
  }

  return mergedResult
}

export default applyAuctionUpdates
