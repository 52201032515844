import type * as React from 'react'

import { SharedFooter } from '@b-stock/bstock-react'

import { ContentContainer } from '@components/ContentContainer'

export const Footer: React.FC = () => {
  return (
    <ContentContainer>
      <SharedFooter />
    </ContentContainer>
  )
}
