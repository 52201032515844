// TODO: Eventually, we should stop using `Layout` and instead use a more reliable mobile detection pattern
/* eslint-disable @typescript-eslint/no-deprecated */
import { DeviceType, Layout, useDeviceInfo } from '@b-stock/bstock-next'

export const useIsNarrow = () => {
  const { layout } = useDeviceInfo()
  return layout === Layout.NARROW
}

export const useIsMedium = () => {
  const { layout } = useDeviceInfo()
  return layout === Layout.MEDIUM
}

export const useIsMobile = () => {
  const { deviceType, layout } = useDeviceInfo()
  return deviceType !== DeviceType.DESKTOP || layout < Layout.WIDE
}
