import type { ComponentPropsWithoutRef } from 'react'

import Link from 'next/link'

import { ButtonAsA } from '@b-stock/bstock-react'

type ButtonLinkProps = ComponentPropsWithoutRef<typeof ButtonAsA>

/**
 * WARNING: Do not remove this component because imported from bstock-next it works incorrectly.
 */

/**
 * Wraps bstock-react's ButtonAsA component with a nextjs Link component, to allow for client-side routing.
 *
 * @example
 * ```tsx
 * import { ButtonLink } from '@components/ButtonLink'
 *
 * const DisputeIdLink = ({ disputeId }: { disputeId: string }) => (
 *   <ButtonLink
 *     appearance="plainTertiary"
 *     href={`/disputes/${encodeURIComponent(disputeId)}/details`}
 *   >
 *     {disputeId}
 *   </ButtonLink>
 * )
 * ```
 *
 * @category Common
 */
export const ButtonLink = ({ href, ...buttonProps }: ButtonLinkProps) => {
  return (
    <Link href={href} passHref legacyBehavior>
      <ButtonAsA
        // This href is passed to satisfy typescript. It will be overwritten by next/link's passHref
        href="#"
        {...buttonProps}
      />
    </Link>
  )
}
