import { styled } from 'styled-components'

import { breakpoints } from '@b-stock/bstock-react/theme'

export const SearchBarContainer = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  padding: 0 2rem;

  @media ${breakpoints.max.medium} {
    padding: 0 0 1rem;
    justify-content: center;
  }
`
