import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { styled } from 'styled-components'

import { Button } from '@b-stock/bstock-react'
import { designColors, typography } from '@b-stock/bstock-react/theme'

export const InputSearch = styled.input`
  ${typography.body2};
  flex: 1;
  box-sizing: border-box;
  outline: none;
  height: 100%;
  padding: 0 1.75rem 0 0.75rem;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  transition: 0.3s;
  border: solid ${designColors.neutral.mediumGray};
  border-width: 0.0625rem 0 0.0625rem 0.0625rem;
  border-radius: 0.25rem 0 0 0.25rem;

  &::placeholder {
    ${typography.body2};
    color: ${designColors.neutral.darkGray};
  }

  &:focus {
    border-color: ${designColors.primary.brightBlue};
  }
`

export const ClearIcon = styled(FontAwesomeIcon)`
  && {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 2.75rem;
    ${typography.body2};
    color: ${designColors.neutral.darkGray};
    height: 1rem;
    width: 1rem;
    opacity: 0;
    transition: 0.3s;
  }

  input:focus ~ & {
    opacity: 1;
  }

  &:hover {
    cursor: pointer;
  }
`

export const SubmitButton = styled(Button)`
  && {
    display: flex;
    width: 2.25rem;
    height: 100%;
    padding: 0.5rem;
    align-items: center;
    justify-content: center;
    border-radius: 0 0.25rem 0.25rem 0;
    cursor: pointer;
    transition: 0.3s;
    background: ${designColors.neutral.white};
    border: solid ${designColors.neutral.mediumGray};
    border-width: 0.0625rem 0.0625rem 0.0625rem 0;

    input:focus ~ & {
      background: ${designColors.primary.hoverOverlay};
      border-color: ${designColors.primary.brightBlue};
    }
  }
`

export const SearchIcon = styled(FontAwesomeIcon)`
  color: ${designColors.neutral.darkGray};

  && {
    width: 0.75rem;
  }

  input:focus ~ ${SubmitButton} & {
    color: ${designColors.primary.brightBlue};
  }
`

export const SearchWrapper = styled.form`
  flex: 1;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 2.25rem;
  max-width: 25rem;

  &:hover {
    & ${ClearIcon} {
      opacity: 1;
    }
  }
`
