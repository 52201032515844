import * as React from 'react'

import { styled, css } from 'styled-components'

import { FormattedMessage, Button } from '@b-stock/bstock-react'
import {
  breakpoints,
  designColors,
  typography,
} from '@b-stock/bstock-react/theme'

import { ButtonLink } from '@components/ButtonLink'
import { useIsNarrow } from '@hooks/layout'

type HeaderMenuLink = {
  label: string
  narrowLabel?: string
  link: string | ((e: React.MouseEvent<HTMLButtonElement>) => void)
  divider?: boolean
}

const HeaderMenu = styled.nav<{ $noBorderTop?: boolean }>`
  grid-area: manage-menu;
  display: flex;
  align-items: center;

  ${(o) =>
    !o.$noBorderTop &&
    css`
      @media ${breakpoints.max.small} {
        margin-top: 1rem;
        border-top: 1px solid ${designColors.neutral.mediumGray};
      }
    `}
`

const ManageLabel = styled.label`
  ${typography.subtitle3}
  color: ${designColors.neutral.darkGray};
`

const ManageLinks = styled.ul<{ $paddingLeft: boolean }>`
  list-style: none;
  display: flex;
  padding-left: ${(o) => (o.$paddingLeft ? '24px' : '0')};
  align-items: center;

  // placeholder for gap property to support safari version <= 14
  & > * + * {
    margin-left: 24px;
  }

  @media ${breakpoints.max.small} {
    & > * + * {
      margin-left: 16px;
    }
  }
`

const ManageLink = styled.li`
  ${typography.subtitle2}

  @media ${breakpoints.max.small} {
    ${typography.subtitle3}
  }
`

const LinkButton = styled(Button).attrs({ appearance: 'plainTertiary' })``

const Divider = styled.div`
  border-left: 1px solid ${designColors.neutral.mediumGray};
  height: 1.125rem;
`

const GenerateHeaderLinks = ({
  headerMenu,
  label,
  noBorderTop,
}: {
  headerMenu?: HeaderMenuLink[]
  label?: React.ReactNode
  noBorderTop?: boolean
}) => {
  const isNarrow = useIsNarrow()

  return headerMenu ? (
    <HeaderMenu $noBorderTop={noBorderTop}>
      {typeof label !== 'undefined' ? (
        <ManageLabel>{label}</ManageLabel>
      ) : (
        <ManageLabel>
          <FormattedMessage
            id="GenerateHeaderLinks.label"
            defaultMessage="GenerateHeaderLinks.label"
          />
        </ManageLabel>
      )}
      <ManageLinks $paddingLeft={label !== null}>
        {headerMenu.map((link) => {
          const label =
            link.narrowLabel && isNarrow ? link.narrowLabel : link.label

          return (
            <React.Fragment
              key={`${typeof link.link === 'string' ? link.link : 'function'}-${link.label}`}
            >
              {link.divider && <Divider />}
              <ManageLink>
                {typeof link.link === 'string' ? (
                  <ButtonLink href={link.link} appearance="plainTertiary">
                    <FormattedMessage id={label} />
                  </ButtonLink>
                ) : (
                  <LinkButton
                    // eslint-disable-next-line react/jsx-handler-names
                    onClick={link.link}
                    size={isNarrow ? 'small' : 'medium'}
                    type="button"
                  >
                    <FormattedMessage id={label} defaultMessage={label} />
                  </LinkButton>
                )}
              </ManageLink>
            </React.Fragment>
          )
        })}
      </ManageLinks>
    </HeaderMenu>
  ) : null
}

export type { HeaderMenuLink }
export default GenerateHeaderLinks
