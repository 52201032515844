import { useIntl } from 'react-intl'

import { Pagination } from '@b-stock/bstock-react'

import type { PaginationControls } from '../UserPageTemplate'

type UserPaginationProps = {
  paginationControls: PaginationControls
}

const UserPagination = ({ paginationControls }: UserPaginationProps) => {
  const { formatMessage } = useIntl()

  const shouldShowPagination =
    paginationControls.total > paginationControls.perPage

  return shouldShowPagination ? (
    <Pagination
      nextPageTitle={formatMessage({ id: `Common.pagination.next` })}
      previousPageTitle={formatMessage({ id: `Common.pagination.previous` })}
      page={paginationControls.currentPage}
      perPage={paginationControls.perPage}
      total={paginationControls.total}
      setPage={paginationControls.setPage}
    />
  ) : null
}

export default UserPagination
