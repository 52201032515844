import { AuctionSearchSortOrder } from '@b-stock/bstock-next'

import type { AuctionSearchSortBy } from '@components/BaseSearchProvider/types'

export const validateSortBy = (
  s: string | string[] | undefined
): AuctionSearchSortBy => {
  if (typeof s !== 'string' || !sortByValues.has(s as AuctionSearchSortBy))
    return 'endTime'

  return s as AuctionSearchSortBy
}

export const validateSortOrder = (
  s: string | string[] | undefined
): AuctionSearchSortOrder => {
  if (typeof s !== 'string' || !sortOrderValues.has(s))
    return AuctionSearchSortOrder.Ascending

  return s as AuctionSearchSortOrder
}

export const sortByValues = new Set<AuctionSearchSortBy>([
  'endTime',
  'startTime',
  'winningBidAmount',
  'numberOfBids',
  'title',
  'retailPrice',
])

export const sortOrderValues = new Set<string>(
  Object.values(AuctionSearchSortOrder)
)
