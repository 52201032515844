import { styled } from 'styled-components'

import { Checkbox, FormattedMessage } from '@b-stock/bstock-react'
import { designColors, typography } from '@b-stock/bstock-react/theme'

import type { AnyFilterState } from '@components/SearchProvider'

import type { ChecklistItem, UpdateFilter } from '../..'

export const Title = styled.div`
  ${typography.subtitle1}
  margin-bottom: 1rem;
`
export const Container = styled.div`
  padding: 0 20px 0.75rem 20px;
  border-bottom: 1px solid ${designColors.neutral.mediumGray};
  margin-bottom: 1rem;
`

const ChecklistContainer = styled.div`
  display: grid;
  padding: 0 8px;
  grid-gap: 1rem;
`

const StyledCheckbox = styled(Checkbox)`
  & span:first-child {
    margin-right: 0.5rem;
  }
`

type MultiChecklistProps<FiltersState> = {
  title?: string
  items: ChecklistItem[]
  filterKey: keyof FiltersState
  filterState: FiltersState
  updateFilter: UpdateFilter<FiltersState>
}

const MultiChecklist = <FiltersState extends AnyFilterState>({
  title,
  items,
  filterKey,
  filterState,
  updateFilter,
}: MultiChecklistProps<FiltersState>) => {
  const selectedFilter = filterState[filterKey] as unknown as string[]
  const handleOnChange = (value: string) => {
    const newFilterState = [...selectedFilter]

    if (newFilterState.includes(value)) {
      newFilterState.splice(newFilterState.indexOf(value), 1)
    } else {
      newFilterState.push(value)
    }

    updateFilter(filterKey, newFilterState)
  }

  return (
    <Container>
      {title && (
        <Title>
          <FormattedMessage id={title} />
        </Title>
      )}
      <ChecklistContainer>
        {items.map((item) => (
          <StyledCheckbox
            onChange={() => handleOnChange(item.value)}
            key={item.value}
            size={16}
            checked={selectedFilter.includes(item.value)}
            label={<FormattedMessage id={item.label} />}
          />
        ))}
      </ChecklistContainer>
    </Container>
  )
}

export default MultiChecklist
