import { useRef, useState } from 'react'
import type { ReactNode, RefObject } from 'react'

import { Modal, Button, FormattedMessage } from '@b-stock/bstock-react'

import { GenerateFilters } from '@components/layouts/UserPageTemplate/DropdownFilter'
import {
  BackButton,
  ButtonContainer,
  SModalContent,
} from '@components/SearchFilters/MobileSearchFilters'
import FilterContainer from '@components/SearchFilters/shared/FilterContainer'
import { formatTitle } from '@components/SearchFilters/shared/filterUtils'
import ShowResultsButton from '@components/SearchFilters/shared/ShowResultsButton'
import type { SelectedFilterType } from '@components/SearchFilters/types'
import type { AnyFilterState } from '@components/SearchProvider'

import type { FilterControls } from '../types'

type UserModalContentProps<FiltersState> = {
  containerRef?: RefObject<HTMLDivElement>
  selectedFilter: string
  selectFilter: (filter: SelectedFilterType) => void
  filterShouldDisplaySeparately: boolean
  filterControls: FilterControls<FiltersState>
}

const UserModalContent = <FiltersState extends AnyFilterState>({
  containerRef,
  filterControls,
}: UserModalContentProps<FiltersState>) => {
  const handleClick = (target: HTMLButtonElement) => {
    containerRef?.current?.scrollTo({
      top: target.offsetTop - 84,
      behavior: 'smooth',
    })
  }

  return (
    <div>
      {filterControls.filterList.map((filter) => {
        const handleExpand = () => {
          // selectFilter({
          //   filterName: name,
          //   itemsCount: props.items ? Object.keys(props.items).length : 0,
          // })
        }

        return (
          <FilterContainer
            key={filter.title}
            label={filter.title}
            expandedOnInit={false}
            onExpand={handleExpand}
            afterExpandAnimation={handleClick}
          >
            <GenerateFilters
              filters={filter.filters}
              filterControls={filterControls}
            />
          </FilterContainer>
        )
      })}
    </div>
  )
}

type MobileFiltersProps<FiltersState> = {
  onClose?: () => void
  onSubmit?: () => void
  closeModal: () => void
  sortComp?: ReactNode
  filterControls: FilterControls<FiltersState>
}

const MobileFilters = <FiltersState extends AnyFilterState>({
  onClose,
  onSubmit,
  closeModal,
  sortComp,
  filterControls,
}: MobileFiltersProps<FiltersState>) => {
  const containerRef = useRef<HTMLDivElement>(null)

  const handleSubmit = () => {
    onSubmit?.()
    closeModal()
  }

  const handleCancel = () => {
    onClose?.()
    closeModal()
  }

  const [selectedFilter, setSelectedFilter] = useState<SelectedFilterType>({
    filterName: '',
    itemsCount: 0,
  })

  const selectFilter = ({ filterName, itemsCount }: SelectedFilterType) => {
    setSelectedFilter({ filterName, itemsCount })
  }
  const handleBackButtonClick = () => {
    setSelectedFilter({
      filterName: '',
      itemsCount: 0,
    })
  }

  const filterShouldDisplaySeparately = !!(
    selectedFilter.filterName && selectedFilter.itemsCount > 8
  )

  return (
    <Modal closeModal={closeModal}>
      <Modal.Header>
        {filterShouldDisplaySeparately ? (
          <>
            <BackButton onPress={handleBackButtonClick} />
            {formatTitle(selectedFilter.filterName)}
          </>
        ) : (
          <FormattedMessage
            id="MobileSearchFilters.header"
            defaultMessage="MobileSearchFilters.header"
          />
        )}
      </Modal.Header>
      <SModalContent ref={containerRef}>
        {sortComp}
        <UserModalContent
          filterShouldDisplaySeparately={filterShouldDisplaySeparately}
          selectedFilter={selectedFilter.filterName}
          selectFilter={selectFilter}
          containerRef={containerRef}
          filterControls={filterControls}
        />
      </SModalContent>
      <div>
        <ButtonContainer>
          <Button appearance="secondary" onClick={handleCancel}>
            <FormattedMessage
              id="MobileSearchFilters.actions.cancel"
              defaultMessage="MobileSearchFilters.actions.cancel"
            />
          </Button>
          <ShowResultsButton
            onSubmit={handleSubmit}
            loading={false}
            results={'12'}
          />
        </ButtonContainer>
      </div>
    </Modal>
  )
}

export default MobileFilters
