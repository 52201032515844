import type * as React from 'react'

import { faTimes } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { styled } from 'styled-components'

import { Button } from '@b-stock/bstock-react'
import { designColors } from '@b-stock/bstock-react/theme'

const MobileSearchContainer = styled.div<{ $show: boolean }>`
  box-sizing: border-box;
  position: sticky;
  top: 0;
  width: 100vw;
  display: ${(o) => (o.$show ? 'block' : 'none')};
  margin: 0 -20px;
  background: ${designColors.neutral.lightGray};
  height: 64px;
  padding: 14px 20px;
  z-index: 100;
  box-shadow: 0px 2px 12px rgba(218, 221, 223, 0.25);
  border: 1px solid ${designColors.neutral.mediumGray};

  @media (min-width: 769px) and (max-width: 991px) {
    margin: 0 -80px;
  }

  @media (min-width: 992px) and (max-width: 1199px) {
    margin: 0 -150px;
  }
`

const ExitButton = styled(Button)`
  position: absolute;
  right: 25px;
  top: 16px;
`

type MobileSearchProps = {
  show: boolean
  renderSearchBar: React.ReactNode
  hideSearch: () => void
}

const MobileSearch = ({
  show,
  renderSearchBar,
  hideSearch,
}: MobileSearchProps) => {
  return (
    <MobileSearchContainer $show={show}>
      {renderSearchBar}
      <ExitButton size="large" onClick={hideSearch}>
        <FontAwesomeIcon icon={faTimes} />
      </ExitButton>
    </MobileSearchContainer>
  )
}

export default MobileSearch
