import { parseISO, format } from 'date-fns'
import isNumber from 'lodash/isNumber'
import type { IntlShape } from 'react-intl'

import type { Range } from '@commonTypes'
import type { AnyFilterState } from '@components/SearchProvider'

import type { DateRangeType } from './DropdownFilter/filtertype/DateRange/DateRange'
import { dateFilterRadios } from './DropdownFilter/filtertype/DateRange/DateRange'
import type { DropdownFilterTypes, FilterControls } from './types'
import { DropdownFilterType } from './types'

const isRange = (filterState: unknown): filterState is Range =>
  Array.isArray(filterState) &&
  filterState.length === 2 &&
  (filterState[0] === null || typeof filterState[0] === 'number') &&
  (filterState[1] === null || typeof filterState[1] === 'number')

const isRangeList = (filterState: unknown): filterState is Range[] =>
  Array.isArray(filterState) && filterState.every((o) => isRange(o))

const isDateRange = (filterState: unknown): filterState is DateRangeType =>
  Array.isArray(filterState) &&
  filterState.length === 2 &&
  (filterState[0] === null || typeof filterState[0] === 'string') &&
  (filterState[1] === null || typeof filterState[1] === 'string')

const isStringList = (filterState: unknown): filterState is string[] =>
  Array.isArray(filterState) && filterState.every((x) => typeof x === 'string')

const isBoolean = (filterState: unknown): filterState is boolean =>
  typeof filterState === 'boolean'

const calculateSelectedFilterCount = <FiltersState extends AnyFilterState>(
  filters: DropdownFilterTypes<FiltersState>[],
  filterControls: FilterControls<FiltersState>
) =>
  filters.reduce((amtSelected, filter) => {
    const filterKey = filter.filterKey
    const type = filter.type
    const filterState = filterControls.filterState[filterKey]

    if (
      isStringList(filterState) &&
      (type === DropdownFilterType.MultiChecklist ||
        type === DropdownFilterType.AlphabetizedMultiChecklist)
    ) {
      return filterState.length + amtSelected
    }

    if (isRange(filterState) && type === DropdownFilterType.MoneyRange) {
      return isNumber(filterState[0]) && isNumber(filterState[1])
        ? amtSelected + 1
        : amtSelected
    }

    if (isDateRange(filterState) && type === DropdownFilterType.DateRange) {
      return filterState[0] && filterState[1] ? amtSelected + 1 : amtSelected
    }

    if (isBoolean(filterState)) {
      return filterState ? amtSelected + 1 : amtSelected
    }

    throw new Error(
      `Tried every type we understand but filterState[${String(
        filterKey
      )}] could not produce a selected filter count`
    )
  }, 0)

const calculateSelectedFilterPriority = <FiltersState extends AnyFilterState>(
  filters: DropdownFilterTypes<FiltersState>[],
  filterCount: number,
  prioritizedFilterKeys: (keyof FiltersState)[]
) => {
  let currPriority = 0
  for (let i = 0; i < prioritizedFilterKeys.length; i++) {
    if (
      filters.findIndex(
        (filter) => filter.filterKey === prioritizedFilterKeys[i]
      ) > -1
    ) {
      if (filterCount > 0) {
        break
      }
    }

    currPriority++
  }

  return currPriority
}

const getTextWidth = (
  text: string,
  font: string,
  canvasElement: HTMLCanvasElement
) => {
  const context = canvasElement.getContext('2d')

  if (context) {
    context.font = font
    const metrics = context.measureText(text)

    return metrics.width
  }

  return 0
}

const calculateFilterAppliedText = <FiltersState extends AnyFilterState>(
  filters: DropdownFilterTypes<FiltersState>[],
  filterControls: FilterControls<FiltersState>,
  intl: IntlShape
) => {
  const filterKey = filters[0].filterKey
  const filterType = filters[0].type
  const filterState = filterControls.filterState[filterKey]

  if (isDateRange(filterState) && filterType === DropdownFilterType.DateRange) {
    const [filterStart, filterEnd] = filterState

    if (!filterStart || !filterEnd) {
      return 'Invalid Date Range'
    }

    const lastDays = dateFilterRadios.lastDays
    const lastYears = dateFilterRadios.lastYears
    for (let i = 0; i < lastDays.length; i++) {
      if (
        lastDays[i].value[0] === filterStart &&
        lastDays[i].value[1] === filterEnd
      ) {
        return intl.formatMessage({
          id: `Filters.${lastDays[i].label}`,
          defaultMessage: `Filters.${lastDays[i].label}`,
        })
      }
    }

    for (let i = 0; i < lastYears.length; i++) {
      if (
        lastYears[i].value[0] === filterStart &&
        lastYears[i].value[1] === filterEnd
      ) {
        return intl.formatMessage({
          id: lastYears[i].label,
          defaultMessage: lastYears[i].label,
        })
      }
    }

    return `${format(parseISO(filterStart), 'P')} - ${format(
      parseISO(filterEnd),
      'P'
    )}`
  }

  if (isRange(filterState) && filterType === DropdownFilterType.MoneyRange) {
    return `$${new Intl.NumberFormat().format(
      filterState[0] ?? 0
    )} — $${new Intl.NumberFormat().format(filterState[1] ?? 0)}`
  }

  throw new Error(
    `Tried every type we understand but filterState[${String(
      filterKey
    )}] could not produce an applied filter text`
  )
}

const getShouldShowFilterApplied = <FiltersState extends AnyFilterState>(
  filters: DropdownFilterTypes<FiltersState>[],
  selectedFiltersCount: number
) => {
  const shouldShowFilterApplied =
    filters.length === 1 &&
    (filters[0].type === DropdownFilterType.DateRange ||
      filters[0].type === DropdownFilterType.MoneyRange)

  return selectedFiltersCount && shouldShowFilterApplied
}

export {
  isRange,
  isRangeList,
  isDateRange,
  isBoolean,
  isStringList,
  getShouldShowFilterApplied,
  calculateSelectedFilterCount,
  calculateSelectedFilterPriority,
  getTextWidth,
  calculateFilterAppliedText,
}
