export const StyledScroll = `
  &::-webkit-scrollbar {
    width: 5px;
  }

  &::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  &::-webkit-scrollbar-thumb {
    background: #888;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
`

export const RoundedScroll = `
&::-webkit-scrollbar {
  width: 5px;
}

&::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 3px;
}

&::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 3px;
}

&::-webkit-scrollbar-thumb:hover {
  background: #555;
}
`
